<template>
  <Account title="Edit profile">
    <div class="columns">
      <div class="column is-5">
        <p class="subtitle">Account details</p>
        <form @submit.prevent="save">
          <div class="columns">
            <div class="column is-6">
              <label class="label is-size-7">Name</label>
              <input
                v-validate="'required'"
                v-model="details.name"
                type="text"
                name="name"
                class="input"
                value="Baz Dealerton"
              />
              <span v-if="errors.first('name')" class="help">{{
                errors.first('name')
              }}</span>
            </div>
            <div class="column is-6">
              <label class="label is-size-7">Email</label>
              <input
                v-validate="'required|email'"
                v-model="details.email"
                type="text"
                name="email"
                class="input"
                value="baz@bmw-newcastle.com"
              />
              <span v-if="errors.first('email')" class="help">
                {{ errors.first('email') }}
              </span>
            </div>
          </div>
          <div v-if="branch" class="columns">
            <div class="column is-6">
              <label class="label is-size-7">Associated dealership</label>
              <input
                :value="branch.name"
                type="text"
                class="input"
                disabled
                readonly
              />
            </div>
            <div v-if="organisation" class="column is-6">
              <label class="label is-size-7">Associated organisation</label>
              <input
                :value="organisation.name"
                type="text"
                class="input"
                disabled
                readonly
              />
            </div>
          </div>
          <button
            :disabled="$wait.is('save-details')"
            :class="{ 'is-loading': $wait.is('save-details') }"
            type="submit"
            class="button is-info"
          >
            Save
          </button>
        </form>
      </div>
      <div class="column">
        <p class="subtitle">Actions</p>
        <a @click="$modal.open('account/AccountPassword')">
          Change my password
        </a>
      </div>
    </div>
  </Account>
</template>

<script>
import { mapGetters } from 'vuex'
import * as account from '../services'
export default {
  name: 'AccountDetails',
  components: {
    Account: () => import('./Account')
  },
  data: () => ({
    details: {
      name: '',
      email: ''
    }
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isBranchAdmin: 'auth/isBranchAdmin',
      branch: 'auth/branch',
      organisation: 'auth/organisation'
    })
  },
  mounted() {
    if (this.user) {
      this.details = this.user
    }
  },
  methods: {
    async save() {
      const valid = await this.$validator.validate()
      if (valid) {
        this.$wait.start('save-details')
        await account.updateUser(this.details)
        this.$wait.end('save-details')
      }
    }
  }
}
</script>
